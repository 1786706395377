<template>
  <div class="wameed-dashboard-page-content">
    <work-header
      v-if="getAllWorks"
      @applyFilter="applyFilter"
      @resetFilter="resetFilter"
      @orderApplicants="orderApplicants"
      :content="content"
    />

    <section
      v-if="getAllWorks"
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
          lg="6"
          md="6"
          class="px-0 py-3"
          v-for="(item, i) in getAllWorks"
          :key="i"
        >
          <works-card
            :id="item.id"
            :isIcon="true"
            :avatar="
              entities[item.entity_type - 1]
                ? entities[item.entity_type - 1].icon
                : ''
            "
            :title="item.name"
            :subTitle="
              entities[item.entity_type - 1]
                ? entities[item.entity_type - 1].text
                : ''
            "
            :date="item.created_at"
            :count="item.count_services + ' '+ $t('requirements')"
            :subDescription="item.budget+' $'"
            :readStatus="item.is_read == 0"
            @cardAction="workDetails"
          />
        </b-col>
      </div>
      <wameed-pagination
        v-if="getTotalWorks.totalItems"
        v-model="filterData.page"
        :page="filterData.page"
        @changePage="changePage"
        :items="allPage"
        :totalItems="getTotalWorks.totalItems"
        :per_page="filterData.per_page"
        @changeCurrentPage="changeCurrentPage"
      />
    </section>
    <wameed-no-data
      v-if="getAllWorks && getAllWorks.length < 1"
      icon="nodata-icon"
      :title="$t('no_data')"
      :subTitle="$t('no_data_subtitle_applications')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WameedPagination from "@/components/WameedPagination.vue";
import WameedNoData from "@/components/WameedNoData.vue";
import WorksCard from "@/components/pages/works/WorksCard.vue";
import WorkHeader from "@/components/pages/works/WorkHeader.vue";

export default {
  components: {
    WameedPagination,
    WameedNoData,
    WorksCard,
    WorkHeader,
  },
  props: {
    isJob: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entities: [
        { id: 1, icon: "individual-icon", text: "individual" },
        {
          id: 2,
          icon: "company-icon",
          text: "company",
        },
        {
          id: 3,
          icon: "organization-icon",
          text: "organization",
        },
        {
          id: 4,
          icon: "government-icon",
          text: "government",
        },
      ],
      allPage: [],
      filterData: {
        budget: "",
        entity_type: "",
        services: "",
        page: 1,
        per_page: 20,
        order_by: "name",
        search: "",
      },
      content: [
        {
          title: "entity_type",
          key: "entity_type",
          id: "id",
          name: "title",
          count: null,
          selectedData: [],
          translate: true,
          data: [
            { id: 1, icon: "individual-icon", title: "individual" },
            {
              id: 2,
              title: "company",
            },
            {
              id: 3,
              title: "organization",
            },
            {
              id: 4,
              title: "government",
            },
          ],
        },
        {
          title: "the_requirements",
          id: "id",
          name: "title",
          count: "count_works",
          key: "services",
          selectedData: [],
          data: [],
        },
        // {
        //   title: "cost",
        //   id: "id",
        //   name: "title",
        //   count: null,
        //   key: "gender",
        //   selectedData: [],
        //   translate: true,
        //   data: [
        //     { title: " 2000$ إلى 5000$ ( المشاريع الصغيرة )", id: 1 },
        //     { title: "5000$ إلى 15,000$ ( المشاريع المتوسطة )", id: 2 },
        //     { title: "10,000$ إلى 50,000$ ( المشاريع الكبيرة )", id: 3 },
        //     { title: "أكثر من 50,000$ ( المشاريع الكبيرة جدآ )", id: 4 },
        //   ],
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllWorks: "getAllWorks",
      getTotalWorks: "getTotalWorks",
      getAllServices: "getAllServices",
    }),
  },
  watch: {
    getTotalWorks(newVal) {
      this.allPage = [];
      for (var i = 1; i <= newVal.totalPages; i++) {
        this.allPage.push({
          title: this.$i18n.t("page") + " / " + i,
          id: i,
        });
      }
    },
    getAllServices(newVal) {
      var services = this.content.findIndex((item) => item.key == "services");
      if (services > -1) {
        this.content[services].data = newVal;
      }
    },
  },
  created() {
    if (this.isJob) {
      this.filterData.job = this.$router.currentRoute.params.id;
    }
    this.loadData();
    this.loadServices();
  },
  methods: {
    ...mapActions({
      loadWorks: "loadWorks",
      loadServices: "loadServices",
    }),

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadWorks(this.filterData);
    },
    workDetails(id) {
      this.$router.push({
        name: "work-details",
        params: { lang: this.$i18n.locale, id: id },
      });
    },
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
 
